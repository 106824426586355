import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { pzn: Number };
  static targets = ["pznDisplay"];
  connect() {
    console.log("pzn modal controller");
  }

  setPzn(el) {
    // window.currPzn = this.pzn;
    let buttonEl;
    buttonEl =
      el.target.tagName === "BUTTON" ? el.target : el.target.closest("button");
    const currPzn = buttonEl.dataset.pznValue;
    this.pznDisplayTargets.forEach((el) => {
      el.innerHTML = "";
      const barcodeImage = document.createElement("img");
      barcodeImage.src = `https://barcode.tec-it.com/barcode.ashx?data=${currPzn}&code=Code39`;
      el.insertAdjacentElement("beforeend", barcodeImage);
    });
  }
}
