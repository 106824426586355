import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  fillForm(ev) {
    // Expected format of fields is data-field-values="field1-value1,field2-value2"
    let btnTarget;
    btnTarget =
      ev.target.tagName.toLowerCase() === "button"
        ? ev.target
        : ev.target.closest("button");
    const fieldValues = btnTarget.dataset.fieldValues;
    const splitValues = fieldValues?.split(",") || [];
    const fields = {};
    splitValues.reduce((prev, curr) => {
      const [name, value] = curr.split("-");
      prev[name.trim()] = value.trim();
      return prev;
    }, fields);

    for (let key of Object.keys(fields)) {
      const inputEl = document.querySelector(`input#${key}`);
      if (inputEl) inputEl.value = fields[key];
    }
  }
}
