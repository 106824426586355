// app/javascript/controllers/notification_setting_day_select_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { delay: { type: Number, default: 1000 } }  // Default delay of 1 second

  initialize() {
    this.timeoutId = null
  }

  toggle(event) {
    // Prevent the default label-click behavior
    event.preventDefault()

    const label = event.currentTarget
    const checkbox = this.element.querySelector(`#${label.getAttribute("for")}`)

    // Toggle the checkbox state
    checkbox.checked = !checkbox.checked

    // Update the label background based on checkbox state
    if (checkbox.checked) {
      label.classList.add("bg-blue-500")
      label.classList.remove("bg-gray-700")
    } else {
      label.classList.add("bg-gray-700")
      label.classList.remove("bg-blue-500")
    }

    // Set a delay before submitting the form
    this.debounceSubmit()
  }

  debounceSubmit() {
    // Clear any existing timeout to reset the delay
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }

    // Set a new timeout to submit the form after the specified delay
    this.timeoutId = setTimeout(() => {
      this.submitForm()
    }, this.delayValue)
  }

  submitForm() {
    const form = this.element.closest("form")
    if (form) {
      form.requestSubmit()
    }
  }

  connect() {
    // Initialize background colors based on checkbox state when the controller is connected
    this.element.querySelectorAll("input[type='checkbox']").forEach((checkbox) => {
      const label = this.element.querySelector(`label[for="${checkbox.id}"]`)
      if (checkbox.checked) {
        label.classList.add("bg-blue-500")
        label.classList.remove("bg-gray-700")
      } else {
        label.classList.add("bg-gray-700")
        label.classList.remove("bg-blue-500")
      }
    })
  }

}
