import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submit", "spinner"]

  connect() {
    this.hideSubmitButton()
    this.addChangeListeners()
  }

  hideSubmitButton() {
    this.submitTarget.classList.add("hidden")
  }

  addChangeListeners() {
    this.element.querySelectorAll("input").forEach((input) => {
      input.addEventListener("change", () => this.handleSubmit())
    })
  }

  handleSubmit() {
    this.showSpinner()
    this.element.requestSubmit()
  }

  showSpinner() {
    this.spinnerTarget.classList.remove("hidden")
  }
}
