import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu", "overlay"]

  toggleDropdown() {
    this.menuTarget.classList.toggle("hidden");
    this.overlayTarget.classList.toggle("hidden");
  }

  closeDropdown(event) {
    // Only close the dropdown if the click is on the overlay
    if (event.target === this.overlayTarget) {
      this.menuTarget.classList.add("hidden");
      this.overlayTarget.classList.add("hidden");
    }
  }
}
