import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["status"]

  connect() {
    // Automatically scroll to the bottom when the controller is first connected
    this.scrollToBottom()

    // Observe changes in the `job-status` div (DOM mutations)
    this.observer = new MutationObserver(() => this.scrollToBottom())
    this.observer.observe(this.statusTarget, { childList: true })
  }

  disconnect() {
    // Stop observing when the controller is disconnected
    if (this.observer) {
      this.observer.disconnect()
    }
  }

  // Scroll to the bottom of the status div
  scrollToBottom() {
  console.log("scroll down!")
    const statusElement = this.statusTarget
    statusElement.scrollTop = statusElement.scrollHeight
  }
}
